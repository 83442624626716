<template>
  <div>404 NOT FOUND 页面未找到</div>
</template>

<script>
export default {

}
</script>

<style>

</style>